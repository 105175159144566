<template>
  <div>
    <div class="table_sty">
      <!-- <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form label-width="150px" :model="formLabelAlign" class="from">
            <el-form-item label="标识名称：">
              <el-input
                placeholder="请输入标识名称"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.name"
              ></el-input>
            </el-form-item>

          </el-form>
          <div class="search_button">
            <div>
              <el-button
                @click="way_tableData(true)"
                type="primary"
                icon="el-icon-search"
                >查询</el-button
              >
              <el-button @click="on_reset" icon="el-icon-refresh-left"
                >重置</el-button
              >
            </div>
          </div>
        </div>
      </div> -->
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个</span>
            <span class="span2">已选择 {{ more_List.length }} 个</span>
            <i class="span_i el-icon-refresh" @click="way_tableData()"
              >数据更新</i
            >
          </p>
          <div>
            <el-button
              type="primary"
              @click="on_pop_addplus()"
              icon="el-icon-plus"
              >新建</el-button
            >
            <el-button @click="on_deleteList" icon="el-icon-close"
              >删除</el-button
            >
            <el-button @click="on_export">导出</el-button>
          </div>
        </div>
        <div>
          <el-table
            highlight-current-row
            @current-change="way_singleselect"
            @selection-change="way_moreselect"
            :data="table_List"
            :header-cell-style="{ background: '#f8f8f9' }"
            style="flex: 1"
          >
            <el-table-column type="selection" width="55"> </el-table-column>

            <el-table-column prop="name" label="学校名称"></el-table-column>
            <el-table-column prop="address" label="学校地址"></el-table-column>

            <el-table-column label="学校LOGO">
              <template slot-scope="scope">
                <el-image
                  style="width: 40px; height: 40px"
                  :src="scope.row.logo"
                >
                </el-image>
              </template>
            </el-table-column>

            <el-table-column prop="address" label="操作">
              <template slot-scope="scope">
                <el-link
                  class="list_sty"
                  type="primary"
                  @click="on_pop_addplus(scope.row.id)"
                  >修改</el-link
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- <div class="paging">
          <el-pagination
            @current-change="way_page"
            :page-size="page_size"
            layout="prev, pager, next, jumper"
            :total="total"
            :current-page="page"
          >
          </el-pagination>
        </div> -->
      </div>
    </div>
    <el-dialog title="修改" :visible.sync="dialog_show" show-close>
      <el-form ref="formName" label-width="120px" :model="save_Data">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="名称："
              prop="name"
              :rules="[{ required: true, message: '请输入名称' }]"
            >
              <el-input
                placeholder="请输入名称"
                size="mini"
                class="form-inp"
                v-model="save_Data.name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="简称："
              prop="alias"
              :rules="[{ required: true, message: '请输入简称' }]"
            >
              <el-input
                placeholder="请输入简称"
                size="mini"
                class="form-inp"
                v-model="save_Data.alias"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="地址："
              prop="address"
              :rules="[{ required: true, message: '请输入地址' }]"
            >
              <el-input
                placeholder="请输入地址"
                size="mini"
                class="form-inp"
                v-model="save_Data.address"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="应用ID："
              prop="agent_id"
              :rules="[{ required: true, message: '请输入应用ID' }]"
            >
              <el-input
                placeholder="请输入应用ID"
                size="mini"
                class="form-inp"
                v-model="save_Data.agent_id"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="应用秘钥："
              prop="secret"
              :rules="[{ required: true, message: '请输入应用秘钥' }]"
            >
              <el-input
                placeholder="请输入应用秘钥"
                size="mini"
                class="form-inp"
                v-model="save_Data.secret"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="学校LOGO："
              prop="logo"
              :rules="[{ required: true, message: '请上传学校LOGO' }]"
            >
              <el-upload
                class="avatar-uploader"
                :action="this.$store.state.uploadImage"
                :headers="this.$tools.config()"
                :data="{
                  path_name: 'danger_icon',
                }"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="save_Data.logo"
                  :src="save_Data.logo"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary"
          >修改</el-button
        >
        <el-button @click="save_Data = false">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import table_minin from "../../assets/minin/table_minin";

import {
  schoolindex,
  schoolsaveinfo,
  schoolsave,
} from "../../assets/request/api";
export default {
  name: "school_management",
  mixins: [table_minin],
  data() {
    return {
      formLabelAlign: {},
      dialog_show: false,
      save_Data: {
        id: "",
        name: "",
        alias: "",
        address: "",
        logo: "",
        agent_id: "",
        secret: "",
      },
    };
  },
  created() {
    this.fromData = this.formLabelAlign;
    this.url = schoolindex;
    this.xl_attribute = "status_list";
    this.way_tableData();
  },
  methods: {
    on_pop_addplus(id) {
      schoolsaveinfo({ id }).then((res) => {
        let { name, alias, address, logo, agent_id, secret } = res.data.info;
        this.save_Data.id = id;
        this.save_Data.name = name;
        this.save_Data.alias = alias;
        this.save_Data.address = address;
        this.save_Data.logo = logo;
        this.save_Data.agent_id = agent_id;
        this.save_Data.secret = secret;
        this.dialog_show = true;
      });
    },
    handleAvatarSuccess(res, file) {
      this.save_Data.logo = res.data.url;
    },
    //上传限制
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 20;
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/bmp";

      if (!isJPG) {
        this.$message.error("上传LOGO图片只能是 JPG ，png ，jpg ，bmp格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 20MB!");
      }
      return isJPG && isLt2M;
    },
    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { save_Data } = this;
          schoolsave(save_Data).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.way_tableData()
              this.dialog_show = false;
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}
.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 200px;
}
.paging {
  text-align: center;
  padding: 10px;
}
.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  button {
    margin-right: 10px;
  }
  p {
    display: flex;
    align-items: center;
  }
}
.span1 {
  font-size: 14px;
  color: #000000;
}
.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}
.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}
.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}
.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}
.list_sty {
  margin-right: 10px;
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
</style>
